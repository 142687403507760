import { render, staticRenderFns } from "./UCIrvineELN1v2Observations3.vue?vue&type=template&id=5627f1c8&scoped=true&"
import script from "./UCIrvineELN1v2Observations3.vue?vue&type=script&lang=ts&"
export * from "./UCIrvineELN1v2Observations3.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5627f1c8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VContainer,VForm,VRow,VTextField})
